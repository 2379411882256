import { applyMiddleware, combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from '../authSlice/authSlice'
import navReducer from '../navSlice/navSlice'
import thunk from 'redux-thunk'
import { useSelector } from 'react-redux'

const reducer = combineReducers({
  authReducer: authReducer,
  navReducer: navReducer,
})
export const store = configureStore({ reducer }, applyMiddleware(thunk))

export const useAppDispatchh = store.dispatch
export const useAppSelector = useSelector
