import api from '../../../auth/api'
const login = async (email, password) => {
  // used api helper function that makes the actual call with axios
  try {
    const res = await api.post('v1/account/login', { email, password })
    return res.data
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const authService = {
  login,
}

export default authService
